<template>
  <div class="el-row mt-5">
    <div class="el-col-24 el-dialog--center">
      <el-image :src="require(`./assets/dacy.png`)" fit="contain"/>
    </div>
  </div>

  <el-card class="mt-3">
    <el-row>
      <el-col :span="10" :offset="7" class="el-dialog--center">
        <h2 class="mt-3 mb-4">Client Einstellungen</h2>
        <h4 class="mb-4">Server</h4>
        <el-row class="mb-4">
          <el-col :span="6">
            <h6>Protokoll</h6>
            <el-select v-model="clientSettings.protocol">
              <el-option value="http">http</el-option>
              <el-option value="https">https</el-option>
            </el-select>
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>IP / Hostname</h6>
            <el-input v-model="clientSettings.ip"></el-input>
            <el-alert type="error" :closable="false" v-show="!validIP && !validHost" >Ungültige IP/Host</el-alert>
          </el-col>
          <el-col :span="6" :offset="1">
            <h6>Port</h6>
            <el-input v-model="clientSettings.port"></el-input>
            <el-alert type="error" :closable="false" v-show="!validPort" >Ungültiger Port</el-alert>
          </el-col>
        </el-row>

        <h4 class="mb-4">GDT</h4>
        <el-row class="mb-4">
          <el-col :span="24">
            <h6>Dateipfad zur GDT Datei ihres AIS</h6>
            <el-input v-model="clientSettings.gdt_path"></el-input>
          </el-col>
        </el-row>

        <el-button type="warning" @click="saveClientSettings" :disabled="!validPort|| (!validIP && !validHost)">Speichern</el-button>
      </el-col>
    </el-row>
  </el-card>


</template>

<script>
import axios from "axios";
import _ from "lodash";
const instance = axios.create({ baseURL: 'http://127.0.0.1:3333' });

export default {
  name: "ClientSettings",
  props: [],
  components: {
  },
  data () {
    return {
      clientSettings: {
        protocol: 'http',
        ip: '',
        port: '8080',
        gdt_path: ''
      }
    }
  },
  mounted: async function () {
    await this.getClientSettings()
  },
  computed: {
    validIP: function () {
      const ipv4 = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
      return this.clientSettings.ip.match(ipv4)
    },
    validHost: function() {
      const host = /^[A-Za-z0-9][A-Za-z0-9-.]*\.\D{2,4}$/
      return this.clientSettings.ip.match(host)
    },
    validPort: function () {
      return this.clientSettings.port.match(/[0-9]/)
    }
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */
  methods: {
    async getClientSettings() {
      const configs = (await instance.get("get-client-settings")).data
      const protocol = _.find(configs, (c) => c.config_key === 'dacyserver_protocol')
      const ip = _.find(configs, (c) => c.config_key === 'dacyserver_host')
      const port = _.find(configs, (c) => c.config_key === 'dacyserver_port')
      const gdt_path = _.find(configs, (c) => c.config_key === 'gdt_path')

      this.clientSettings.protocol = protocol ? protocol.config_value : 'http'
      this.clientSettings.ip = ip ? ip.config_value : ''
      this.clientSettings.port = port ? port.config_value : '8080'
      this.clientSettings.gdt_path = gdt_path ? gdt_path.config_value : ''

    },
    async saveClientSettings() {

      await instance.post("get-client-settings", {
        ...this.clientSettings
      })

      location.href = '/'

    }
  }
}
</script>
<style>
body {
  overflow: hidden;
  background-color:#FFF !important;
}
</style>
